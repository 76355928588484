
import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AnimatePresence, motion } from "framer-motion";

import styles from "../../styles/startpage.module.scss";

import { AppContext } from '../../AppContext';

const Navigation = () => {

  const { language, updateLanguage, view, setView } = useContext(AppContext);

  const handleLanguageChange = (e) => {
    e.preventDefault();
    const newLanguage = language === "en-US" ? "es-PR" : "en-US";
    updateLanguage(newLanguage);
  };

  return (
    <nav className={styles.nav}>

      <div
        href={1}
        className={`${styles.icons} ${view === "home" ? styles.iconsActive : ""}`}
        onClick={() => setView("home")}
      >
        <img
          src="/map/icon__ship--4.png"
          alt="placeholder"
        />
        <span className={styles.tooltip}>
          {language === "en-US" ? "Home" : "Inicio"}
        </span>
      </div>

      <div
        href={1}
        className={`${styles.icons} ${view === "menu" ? styles.iconsActive : ""}`}
        onClick={() => setView("menu")}
      >
        <img
          src="/map/icon__ship--4.png"
          alt="placeholder"
        />
        <span className={styles.tooltip}>
          {language === "en-US" ? "Menu" : "Menú"}
        </span>
      </div>

      <div
        href={1}
        className={`${styles.icons} ${view === "about" ? styles.iconsActive : ""}`}
        onClick={() => setView("about")}
      >
        <img
          src="/map/icon__ship--2.png"
          alt="placeholder"
        />
        <span className={styles.tooltip}>
          {language === "en-US" ? "About" : "Acerca"}
        </span>
      </div>

      {/* <div
        href={1}
        className={`${styles.icons} ${view === "contact" ? styles.iconsActive : ""}`}
      >
        <img
          src="/map/icon__ship--3.png"
          alt="placeholder"
        />
        <span className={styles.tooltip}>
          {language === "en-US" ? "Contact" : "Contacto"}
        </span>
      </div> */}

      <div
        href={1}
        className={`${styles.icons} ${view === "store" ? styles.iconsActive : ""}`}
        onClick={() => {
          // setView("store")
          window.location.href = "https://store.cervezascamacho.com";
        }}
      >
        <img
          src="/map/icon__ship--5.png"
          alt="placeholder"
        />
        <span className={styles.tooltip}>
          {language === "en-US" ? "Store" : "Tienda"}
        </span>
      </div>

      <div
        href={1}
        className={`${styles.icons} ${styles.iconsLanguage}`}
        onClick={(e) => handleLanguageChange(e)}
      >
        <div className={styles.iconsLanguageInner}>
          <img
            src="/map/icon__ship--1.png"
            alt="placeholder"
          />
          <span className={styles.tooltip}>
            {language === "en-US" ? "Language" : "Idioma"}
          </span>
        </div>

        {language === "en-US" ? (
          <img
            className={styles.iconsLanguageFlag}
            src="/flags/flag__us.png"
            alt="EN"
          />
        ) : (
          <img
            className={styles.iconsLanguageFlag}
            src="/flags/flag__pr.png"
            alt="ES"
          />
        )}

      </div>

    </nav>
  );
};

export default Navigation;