import React, {
  useContext,
  useEffect,
  // useState,
  useRef
} from 'react';
import { Routes, Route } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

// Components
// import Feed from './components/feed/feed';
// import Links from './components/links/links';
import Navigation from './components/nav/nav';
import Home from './components/home/home';
import About from './components/about/about';
import Menu from './components/menu/menu';
import Display from './components/display/display';
import Store from './components/store/store';

// Motion Variants
import {
  variantsFade,
  variantsFadeBackground
} from './utils/variants';

import styles from './styles/startpage.module.scss';

import { AppContext } from './AppContext';

function Container() {

  const ref = useRef(null);
  const { view, setView, content } = useContext(AppContext);

  useEffect(() => {
    if (content.menu && content.menu.length > 0) {
      if (window.location.pathname === "/display") {
        setView("display");
      }
      if (window.location.pathname === "/store") {
        // setView("store");
        window.location.href = "https://store.cervezascamacho.com";
      }
    }
  }, [setView, content]);

  if (!content && !content.menu) return <div>Loading...</div>;

  return (
    <>
      <main className={styles.main}>

        <AnimatePresence key="main">
          {/* Background */}
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variantsFadeBackground}
            transition={{
              duration: 2,
              ease: "easeInOut",
            }}
            key={AppContext}
          >
            <div
              className={styles.background}
              style={{
                backgroundImage: `url(bg.jpg)`,
              }}
            />
          </motion.div>
        </AnimatePresence>

        {/* Header */}
        {view !== 'display' && (
          <AnimatePresence key={`header`}>
            <div className={styles.header}>
              {/* Navigation */}
              <Navigation />
            </div>
          </AnimatePresence>
        )}

        {/* Container */}
        <motion.div
          className={styles.container}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variantsFade}
          ref={ref}
          key={view}
        >
          <AnimatePresence key="container">

            <Routes>
              <Route exact path='' component={Home} />
              <Route exact path='/' component={Home} />
              <Route exact path='/menu' component={Menu} />
              <Route exact path='/store' component={Store} />
              <Route exact path='/about' component={About} />
            </Routes>

            {/* Links Navigations */}
            {view === "home" && <Home />}
            {view === "about" && <About />}
            {view === "store" && <Store />}
            {view === "menu" && <Menu />}

          </AnimatePresence>


        </motion.div>
      </main>
      <Routes>
        <Route exact path='/display' component={Display} />
      </Routes>
      {view === "display" && <Display />}
    </>
  );

}

export default Container;
